import React from 'react'
import { Link } from 'gatsby'
import Details from '../Details'
import styles from './PostLink.module.css'

function PostLink({ title, date, tags, slug }) {
  return (
    <article className={styles.base}>
      <h1 className={styles.heading}>
        <Link className={styles.link} to={slug}>
          {title}
        </Link>
      </h1>
      <Details date={date} tags={tags} />
    </article>
  )
}

export default PostLink
