import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PostLink from '../components/PostLink'
import SEO from '../components/seo'

function BlogIndex({ data }) {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges || []

  return (
    <Layout title={siteTitle}>
      <SEO title="All posts" />
      {posts && posts.map(({ node }, i) => {
        const title = node.frontmatter.title || node.fields.slug
        const tags = node.frontmatter.tags
        const date = node.frontmatter.date

        return (
          <PostLink
            key={node.fields.slug}
            title={title}
            date={date}
            tags={tags}
            slug={node.fields.slug}
          />
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY")
            title
            tagline
            description
            tags
          }
        }
      }
    }
  }
`
